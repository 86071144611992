import React, { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import './DemoEmailDialog.css'; // Import the CSS file
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://blcgxxigwqgjstusqmho.supabase.co';
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

let supabase;
try {
  supabase = createClient(supabaseUrl, supabaseKey);
} catch (error) {
  console.error('Error initializing Supabase client:', error);
}

function DemoEmailDialog({ open, onClose }) {
  useEffect(() => {
    if (open) {
      mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: true, track_pageview: true, persistence: 'localStorage' });
      mixpanel.track('DemoEmailDialogOpened');
    }
  }, [open]);

  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { width, height } = useWindowSize();

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsValidEmail(validateEmail(emailValue));
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidEmail) {
      setLoading(true);
      try {
        // Send email API call
        const emailPromise = axios.post('https://email.echovane.com/send-email/', { email });

        // Supabase insert API call
        const supabasePromise = supabase
          .from('demo_trials')
          .insert([{ email }]);

        // Execute both API calls in parallel
        await Promise.all([emailPromise, supabasePromise]);

        setEmail('');
        setErrorMessage('');
        setSuccess(true);
        mixpanel.track('DemoEmailSubmitted');
      } catch (error) {
        console.error('Error sending email:', error);
        setErrorMessage('We are out of capacity right now, please try again later.');
      } finally {
        setLoading(false);
      }
    }
  };

  const dialogWidth = width > 600 ? '600px' : '80%';
  const dialogHeight = height > 400 ? '300px' : '50%';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        style: {
          width: dialogWidth,
          height: dialogHeight,
        },
      }}
    >
      <DialogContent className="dialog-content">
        {success ? (
          <div className="success-container">
            <Confetti
              width={width}
              height={height}
              numberOfPieces={200}
              recycle={false}
            />
            <div className="success-message">
              <CheckCircleIcon className="success-icon" />
              <p className="success-text">
                We've sent you a demo link with instructions. Please check your inbox!
              </p>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="email-form">
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              required
              autoComplete="email"
              inputProps={{ maxLength: 100 }}
              error={!isValidEmail}
              helperText={!isValidEmail && "Please enter a valid email"}
              FormHelperTextProps={{ className: "helper-text" }}
            />
            <p className="email-info">
              We will share your demo link on your email, no signup is needed.
            </p>
            {errorMessage && (
              <p className="error-message">
                {errorMessage}
              </p>
            )}
            <div className="submit-button-container">
              <Button
                variant="contained"
                type="submit"
                disabled={!email || !isValidEmail || loading}
                className='signup-button'
              >
                Submit
              </Button>
              {loading && <CircularProgress size={24} className="loading-spinner" />}
            </div>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default DemoEmailDialog;
